@tailwind base;
@tailwind components;
@tailwind utilities;

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #000000;
}

::-webkit-scrollbar-thumb {
  background: #6FC400;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #6FC400;
}

.loading-logo {
  animation: rotate-logo 2s linear infinite;
}

@keyframes rotate-logo {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


